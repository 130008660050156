<template>
  <div class="dataManagement" v-loading="loading">
    <div class="searchBox">
      <el-input v-model="filter" prefix-icon="el-icon-search" clearable placeholder="输入文件名" size="medium" @change="getList"
        @clear="clear"></el-input>
    </div>
    <div class="fileList">
      <div class="fileItem" v-for="item in fileList" :key="item.id" @click="preview(item.filePath)">
        <img class="image" src="../../../public/img/wenj.png"/>
        <span>{{ item.fileName }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileList: [],
      loading: false,
      filter: "",
      id: ""
    };
  },

  mounted() {
    this.id = this.$route.query.id;
    this.getList();
  },

  methods: {
    preview(url) {
      window.open("#/pdf?url=" + url, "_blank");
    },

    getList() {
      this.loading = true;
      this.$http.get(`fileManages/${this.id}?filter=${this.filter}`).then(res => {
        this.loading = false;
        this.fileList = res.data.fileManageDetailVOS;
      }).catch(() => {
        this.loading = false;
      });
    },

    clear() {
      this.filter = "";
      this.getList();
    }
  }
};
</script>

<style lang="scss" scoped>
.dataManagement {
  height: 100%;
  box-sizing: border-box;

  .searchBox {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px 15px 0 15px;
    z-index: 99999;
    background-color: #fff;
  }

  .fileList {
    padding-top: 46px;
  }

  .fileItem {
    padding: 10px;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    align-items: center;

    .image {
      width: 35px;
      height: 35px;
    }
  }
}
</style>